import React from 'react'
import EmbeddedImage from './components/EmbeddedImage'
import EmbeddedImageContainer from './components/EmbeddedImageContainer'
import EmbeddedMediaContainer from './components/EmbeddedMediaContainer'

export const PreviewEmbeddedAsset = ({
  title,
  align,
  width,
  wrapText,
  internalLinkCollection,
  internalLink,
  mediaCollection,
  media,
  url,
  modal,
  displayTitle,
  type,
  file,
  linkId,
  id,
  sys,
  ...rest
}) => {
  const internalLinkItems =
    internalLinkCollection?.items ||
    (internalLink ? [].concat(internalLink) : [])

  const slug = internalLinkItems[0]?.slug
  const typename = internalLinkItems[0]?.__typename
  const internalCategory = internalLinkItems[0]?.category?.slug

  const mediaItems = mediaCollection?.items || (media ? [].concat(media) : [])

  const imageObj = {
    imageSrc: mediaItems[0]?.url || mediaItems[0]?.file?.url || file,
    slug: {
      internalSlug: slug,
      typename,
      internalCategory,
    },
    externalResource: url,
    title,
    width,
    align,
    wrapText,
    displayTitle,
  }
  const previewVideoSrc = mediaItems[0]?.url || mediaItems[0]?.file?.url || file
  const videoObj = {
    url,
    align,
    width,
    displayTitle,
    modal,
    media: [
      {
        file: {
          url: previewVideoSrc,
          contenType:
            mediaItems[0]?.contentType ||
            mediaItems[0]?.file?.contentType ||
            type,
        },
      },
    ],
  }
  return type === 'Image' ? (
    <EmbeddedImage {...imageObj} />
  ) : (
    <EmbeddedMediaContainer {...videoObj} />
  )
}

const EmbeddedAsset = ({ node, references, moduleData }) => {
  const asset = references?.find(r => r.id === node?.data?.target?.sys?.id)
  // ContentfulAsset
  const assetType = asset?.file?.url?.includes('image')
  // ContentfulImageVideo Embed
  const type = moduleData?.type

  return type === 'Image' || assetType ? (
    <EmbeddedImageContainer {...asset} {...moduleData} />
  ) : (
    <EmbeddedMediaContainer {...moduleData} {...asset} />
  )
}

export default EmbeddedAsset
