import React from 'react'
import { StyledContentContainer } from '../style'

const StackedInsightsBanner = ({ children, ...otherProps }) => {
  const hasArticleGrid =
    children &&
    children[1] &&
    children[1].props &&
    children[1].props.articleGrid
  const hasLightGreyBackground =
    hasArticleGrid &&
    children[1].props.articleGrid.backgroundColor === 'Light grey'

  if (children && children.length > 2 && !children[1]) {
    return (
      <>
        <div>
          <StyledContentContainer
            flexDirection
            noPaddingBottom={hasLightGreyBackground}
            {...otherProps}
          >
            {children[0]}
          </StyledContentContainer>
        </div>
        {children[2]}
      </>
    )
  }
  return (
    <>
      <div>
        <StyledContentContainer
          flexDirection
          noPaddingBottom={hasLightGreyBackground}
          {...otherProps}
        >
          {children[0]}
        </StyledContentContainer>
        {children[1]}
      </div>
      {children[2]}
    </>
  )
}

export default StackedInsightsBanner
