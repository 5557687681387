import React from 'react'
import { StyledModalImageDiv, StyledThumbnail } from '../style'
import VideoModal from './VideoModal'
import { useImageSrc } from '../../../hooks/useVideoSrc'

const ToggledModal = ({
  toggle,
  align,
  margin,
  wrapText,
  thumbnail,
  handleModal,
  videoSrc,
}) => {
  const { imgSrc, handleImageError } = useImageSrc(thumbnail)
  return (
    <StyledModalImageDiv
      onMouseUp={handleModal}
      align={align}
      wrapText={wrapText}
      margin={margin}
      data-testid="toggled"
    >
      <VideoModal
        toggle={toggle}
        url={videoSrc}
        aria-labelledby="video-modal"
      />
      <StyledThumbnail
        onMouseUp={handleModal}
        src={imgSrc}
        onError={handleImageError}
      />
    </StyledModalImageDiv>
  )
}

export default ToggledModal
