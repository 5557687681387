import React, { useEffect } from 'react'
import { RawHtml, RawHtmlSpan } from './style'

const Html = props => {
  useEffect(() => {
    if (document) {
      const links = document.querySelectorAll('a')
      const footnotes =
        links?.length > 0 &&
        Array.from(links)?.filter(
          link => link?.name?.length > 0 && link?.href?.includes('#_')
        )
      if (footnotes) {
        footnotes.forEach(footnote => {
          footnote.id = footnote.name
        })
      }

      const anchorLinks = Array.from(links).filter(
        link => link.href && link.href.includes('#') && !link.onclick
      )

      anchorLinks.forEach(link => {
        link.addEventListener('click', e => {
          e.preventDefault()
          const targetId = link.href.split('#')[1]
          const targetElement = document.getElementById(targetId)

          if (targetElement) {
            // get the actual height of the navigation bar
            const navElement = document.getElementById('navigation')
            const navbarOffset = navElement.offsetHeight
            window.scrollTo({
              top: targetElement.offsetTop + navbarOffset + 35, // adding a little extra space
            })
          }
        })
      })
    }
  }, [])

  return props.inline ? (
    <RawHtmlSpan
      professional={props.professional}
      dangerouslySetInnerHTML={{
        __html: props?.contentHtml?.html,
      }}
    />
  ) : (
    <RawHtml
      professional={props.professional}
      dangerouslySetInnerHTML={{
        __html: props?.contentHtml?.html,
      }}
    />
  )
}

export default Html
