import React from 'react'
import ArticleCard from './subcomponents/ArticleCard'
import { Container } from '@components/Common'
import A from '@components/A'
import styled from 'styled-components'

const FullWidthBackground = styled.div`
  margin: 0 -70px;
  padding: 0 70px;
  background-color: ${props => props.bgColor || 'transparent'};

  @media (max-width: 1200px) {
    margin: 0 -26px;
    padding: 0 26px;
  }

  @media (max-width: 768px) {
    margin: 0 -20px;
    padding: 0 20px;
  }
`

const ArticleGrid = ({
  titleContent,
  articles,
  columnLayout,
  ctaTitle,
  ctaLink,
  topPadding,
  bottomPadding,
  backgroundColor,
}) => {
  const topClass = topPadding !== false ? 'pt-[40px]' : ''
  const bottomClass = bottomPadding !== false ? 'pb-[40px]' : ''

  // Determine whether to use the FullWidthBackground component
  const useGreyBackground = backgroundColor === 'Light grey'

  // Content to render inside the container
  const renderContent = () => (
    <>
      <div
        className="mb-5"
        dangerouslySetInnerHTML={{ __html: titleContent?.html }}
      />
      <div
        className={`grid grid-cols-1 ${
          columnLayout === 'Three Columns' ? 'md:grid-cols-3' : 'md:grid-cols-2'
        } md:gap-4 gap-10`}
      >
        {articles.map(article => (
          <ArticleCard
            key={article.id}
            id={article.id}
            description={article.description}
            image={article.image}
            columnLayout={columnLayout}
          />
        ))}
      </div>

      {ctaTitle && ctaLink && (
        <div className="mt-12 text-[22px] font-bold text-center">
          <A
            to={ctaLink}
            target="_self"
            className="text-ph-gold hover:underline hover:underline-offset-2"
          >
            {ctaTitle}
          </A>
        </div>
      )}
    </>
  )

  // Render with or without the FullWidthBackground
  return (
    <div className="w-full">
      {useGreyBackground ? (
        <FullWidthBackground bgColor="#E8E8E8">
          <Container className={`px-[15px] md:px-0 ${topClass} ${bottomClass}`}>
            {renderContent()}
          </Container>
        </FullWidthBackground>
      ) : (
        <Container className={`px-[15px] md:px-0 ${topClass} ${bottomClass}`}>
          {renderContent()}
        </Container>
      )}
    </div>
  )
}

export default ArticleGrid
